<template>
  <div>
    <modal :show.sync="$store.state.modals.scheduleMessageVisibility">
      <h6 slot="header" class="modal-title" id="modal-title-default">Edit Scheduled Message</h6>

      <validation-observer v-slot="{handleSubmit}" ref="scheduledMessageForm">
        <form v-loading="loaders.modal" class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
          <div>
            <base-input label="Message" :rules="{required: true}" name="message">
                  <textarea v-model="scheduledMessage.form.message"
                            class="form-control"
                            rows="4">
                  </textarea>
            </base-input>
          </div>

          <div class="mt-4 row">
            <div class="col-6 pr-0">
            <base-input label="Date" :rules="{required: true}" name="date">
              <div>
                <b-input-group class="mb-3">
                  <el-input
                    style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                    v-mask="'##/##/####'"
                    id="example-input"
                    v-model="scheduledMessage.form.date"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    autocomplete="off"
                    @blur="handleDate(scheduledMessage.form.date)"
                  ></el-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      :min="minDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                      @context="onContextDate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </base-input>
          </div>
          <div class="col-6">
            <base-input label="Time*" :rules="{required: true}" name="time">
              <base-time-picker
                v-model="scheduledMessage.form.time"
                placeholder="Select time"
              />
            </base-input>
          </div>
          </div>
          <div class="scrollable checkbox-container-height">
            <label>Select Stage</label>
            <div class="">
              <div class="stage_box pt-3 pl-3 d-inline-block" v-for="(stage,index) in stages" :class="{'margin-right-8':index%2==0}">
                <el-checkbox v-model="stage.value">{{stage.label}}</el-checkbox>
              </div>
            </div>

            <template v-if="!categoriesEmpty">
              <template v-for="(category,index) in categories">
                <label class="lbl-select-stage pt-2"> {{ category.name }}</label>
                <template v-if="!subCategoriesEmpty(index)">
                  <el-checkbox-group
                    v-model="scheduledMessage.form.subCategories[category.id]"
                  >
                    <template v-for="(subCategory) in category.user_sub_categories">
                      <div class="categoryBox mt-2 pt-3 pl-3 d-inline-block">
                        <el-checkbox :label="subCategory.id"
                                     :key="subCategory.id"
                        >{{ subCategory.name }}
                        </el-checkbox>
                      </div>
                    </template>
                  </el-checkbox-group>
                </template>
                <div v-else>
                  <h3 class="text-center"> No {{ category.name }} Available </h3>
                </div>
              </template>
            </template>
          </div>


          <div class="float-right mt-4">
            <base-button type="secondary" class="ml-auto" @click="$store.commit('hideEditScheduleMessageModal')">
              Cancel
            </base-button>
            <base-button :loading="loaders.modal" native-type="submit" type="submit" class="btn-primary-action">Update
            </base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  props: ['scheduleMessageId','categories'],
  name: "EditScheduledMessageModal",
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      loaders: {
        modal: false,
      },
      scheduledMessage: {
        form: {
          id: '',
          message: '',
          date: '',
          time: '',
          stages: [],
          subCategories: {}
        },
      },
      stages:[],
      currentSubCategory: [],
      tempData : {
        '25': 'Considering Surgery',
        '50': 'Pre-Op',
        '75': 'Post-Op',
        '150': 'Non-Surgical',
      },
    }
  },
  computed : {
    categoriesEmpty(){

      return (typeof (this.categories) === 'undefined') ||
        (Object.keys(this.categories).length <= 0);
    }
  },
  methods: {
    handleDate(e) {
      if (moment(e).format('MM/DD/YYYY') == 'Invalid date') {
        this.scheduledMessage.form.date = '';
      }else if (moment(this.scheduledMessage.form.date) < moment(this.today)) {
        this.scheduledMessage.form.date = moment(this.today).format('MM/DD/YYYY');
      }
    },
    onContextDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.scheduledMessage.form.date = ctx.selectedFormatted
      }
    },
    addCategoryToApi(){
      if (!this.categoriesEmpty) {
        for (let i = 0; i < this.categories.length; i++) {
          this.$set(this.scheduledMessage.form.subCategories, `${this.categories[i].id}`, [])
        }
      }
    },

    addSubCategoriesToCategories(){
      if (
        (this.currentSubCategory !== null) ||
        (typeof (this.currentSubCategory) !== 'undefined') ||
        (this.currentSubCategory.length >0)
      ){
        for (const subCategory of this.currentSubCategory) {
          for (let i =0;i<this.categories.length;++i) {
            for (const object of this.categories[i].user_sub_categories) {
              if (object.id  === parseInt(subCategory)){
                const keys = Object.keys(this.scheduledMessage.form.subCategories)
                let index = this.scheduledMessage.form.subCategories[keys[i]].length
                this.$set(this.scheduledMessage.form.subCategories[keys[i]],index,parseInt(subCategory))
                break
              }
            }
          }
        }
      }
    },

    subCategoriesEmpty(categoryId){
      return (typeof (this.categories[categoryId].user_sub_categories) === 'undefined') ||
        (Object.keys(this.categories).length <= 0) ||
        (this.categories[categoryId].user_sub_categories.length <= 0);
    },

    submitForm() {
      this.handleDate(this.scheduledMessage.form.date);
      var stagesArr = [];
      for(const x in this.stages){
        if(this.stages[x].value){
          stagesArr.push(this.stages[x].key);
        }
      }
      let vm = this;
      vm.loaders.modal = true
      axios.patch(vm.$store.getters.getBaseUrl + `/api/scheduled-messages/update`,{
        id : this.scheduledMessage.form.id,
        message : this.scheduledMessage.form.message,
        date : this.scheduledMessage.form.date,
        time: this.scheduledMessage.form.time,
        timeZoneOffset: new Date().getTimezoneOffset(),
        stages:stagesArr.join(),
        subCategories: this.scheduledMessage.form.subCategories
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.$notify.success({
            title: 'Success',
            message: 'Scheduled Message Updated Successfully'
          });
          vm.resetFormFields();
          this.$route.name === 'ScheduledMessages' ? this.$emit('scheduleMessageUpdated')
            : this.$emit('updateScheduleMessage');
        }).catch(error => {

      }).finally(() => {
        vm.loaders.modal = false
      });
    },

    resetFormFields(){
      this.scheduledMessageId = '';
      this.scheduledMessage.form.id = '';
      this.scheduledMessage.form.message = '';
      this.scheduledMessage.form.date = '';
      this.scheduledMessage.form.time = '';
      for (const key of Object.keys(this.scheduledMessage.form.subCategories)) {
        this.scheduledMessage.form.subCategories[key].splice(0,this.scheduledMessage.form.subCategories[key].length)
      }
    },

    getStagesAndCategories(){
      for (let tempDataKey in this.tempData) {
        this.stages.push({label:this.tempData[tempDataKey],key:tempDataKey,value:false});
      }
    },

    getScheduledMessage(id) {
      let vm = this;
      axios.get(vm.$store.getters.getBaseUrl + `/api/scheduled-messages/${id}/show`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
            let currentStages = [];
            vm.scheduledMessage.form.id = response.data.data.id;
            vm.scheduledMessage.form.message = response.data.data.message;
            vm.scheduledMessage.form.date = response.data.data.date;
            vm.scheduledMessage.form.time = response.data.data.time;
            if (response.data.data.stage) {
              currentStages = response.data.data.stage.split(',');
              currentStages.forEach((x, index) => {
                let i = vm.stages.findIndex(row => row.key == x);
                if (i != -1) {
                  vm.stages[i].value = true;
                }
              })
            }
            vm.currentSubCategory = response.data.data.user_category.split(',');
            this.addSubCategoriesToCategories()

        }).catch(error => {

        }).finally(() => {

      });
    }
  },
  watch:{
    categories(newVal){
      this.categories = newVal
      this.addCategoryToApi()
    }
  },
  mounted() {
    this.getStagesAndCategories();
    this.addCategoryToApi()
    // this.getScheduledMessage();
  },
};
</script>

<style scoped>
.stage_box {
  width: 212px;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
  margin-bottom: 8px;
}

.margin-right-8 {
  margin-right: 8px;
}

.lbl-select-stage {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.categoryBox{
  width: 212px;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

div[class*='categoryBox']:nth-child(even){
  margin-left: 0.5rem;
}

.scrollable{
  overflow-y: auto;
}

.checkbox-container-height{
  height: 34vh;
  min-height: 276px;
}

</style>
