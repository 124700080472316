<template>
  <div>
    <div class="d-flex justify-content-between general-pagination">

      <div class="d-flex align-items-center ml-2">
        <div class="current-pagination">
          {{ getCurrentData }}
        </div>
        <div class="pagination-separator ml-3 mr-3">
          <span class="pagination-separator">|</span>
        </div>
        <div class="pagination-changer">
          Items per page
          <el-select
            class="select-primary pagination-select paginated-table-select ml-2"
            @change="changedPageLength"
            v-model="paginationSelected"
            size="mini"
          >
            <el-option
              class="select-primary"
              v-for="(page, index) in pageLengths"
              :key="index"
              :label="page"
              :value="page"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <base-pagination
        class="pagination-no-border pointer float-right mr-3 mt-2"
        v-model="pagination.currentPage"
        size="sm"
        :perPage="parseInt(pagination.perPage)"
        :total="pagination.total"
        @input="changePage"
        pagination-footer
      />
    </div>

  </div>
</template>

<script>
import {BasePagination} from "@/components";

export default {
  name: "PaginationFooter",
  props: ['pagination'],
  components: {
    BasePagination,
  },
  data() {
    return {
      pageLengths: [1,2, 5, 10, 15, 20],
      paginationSelected: 20,
    }
  },
  computed: {
    getCurrentData() {
      return `Page ${this.pagination.currentPage ?? 1} of ${this.pagination.lastPage ?? 1}`
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
      this.$emit('input', page);
    },
    changedPageLength(value) {
      this.$emit('changedPageLength', value);
    },
  },
  watch: {

  }
}
</script>

<style>
.general-pagination {
  margin-left: 20px;
}
.general-pagination .pagination-changer .el-input {
  width: 150px!important;
}

.general-pagination .pagination-changer .el-select .el-input .el-input__inner {
  height: 30px !important;
}

</style>
